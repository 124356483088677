import React from "react"
import { graphql, GatsbyImage } from "gatsby"
import Layout from "../components/Layout"
import BackLink from "../components/BackLink"
import SEO from "../components/SEO"
import { renderRichText } from 'gatsby-source-contentful/rich-text'

export const ComponentName = ({data}) => {
  const { content, title, description, image, author, date} = data.blog;

  const options = {
    renderNode: {
      "embedded-asset-block": node => {
        const  image  = node.data.target.fluid.src
        console.log(image);
        if (!image) {
          // asset is not an image
          return null
        }
        return <img src={image} />
      },
    },
  }

  return <Layout indicator themeSwitch>
    <SEO title={title} description={description ? description.description : ""}/>
    <section className="blog-post section-center-narrow section-padding">
    <BackLink title="All Posts" page="media"/>
      <div className="section-center section-padding">
        <article className="blog-content">
          <div className="blog-top">
            <p>{date}</p>
            <p>By {author}</p>
          </div>
          <h1>{title}</h1>
          <img className="hero-img" src={image ? image.fluid.src : null} />
          <div>{renderRichText(content, options)}</div>
        </article>
      </div>
    </section>
  </Layout>
}

export const query = graphql`
  query GetSingleBlogPost($slug: String) {
    blog: contentfulBlogPost(slug: { eq: $slug }) {
      content {
        raw
        references {
          id
          contentful_id
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData
            fluid {
              src
            }
          }
        }
      }
      contentful_id
      title
      description {
        description
      }
      image {
        fluid {
          src
        }
      }
      author
      date
    }
  }
`

export default ComponentName
